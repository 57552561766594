import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ durationInMinutes, onTimeUpdate }) => {
  const [timeRemaining, setTimeRemaining] = useState(durationInMinutes * 60); // Initialize with total seconds

  useEffect(() => {
    if (timeRemaining <= 0) {
      
      onTimeUpdate(0); // Ensure zero time is also reported
      return; // Stop the timer if time has run out
    }

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = Math.max(prevTime - 1, 0); // Ensure time doesn't go below zero
        onTimeUpdate(newTime); // Update parent with new time
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up interval on unmount
  }, [timeRemaining, onTimeUpdate]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <div className="timebox text-center">
      {timeRemaining > 0 ? `${minutes} Minutes ${seconds < 10 ? '0' : ''}${seconds} Seconds` : 'Time is up!'}
    </div>
  );
};
export default CountdownTimer;
