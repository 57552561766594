import { apiFunction } from "./functions";

export const getSignup = async (val) => {
    return await apiFunction(`auth/signup`, "post", val, false,  null)
}


export const getLogin = async (val) => {
    return await apiFunction(`auth/signin`, "post", val, false,  null)
}

export const getSocialLogin = async (val) => {
    return await apiFunction(`auth/social/login`, "post", val, false,  null)
}

export const getAllSubjects = async (val) => {
    return await apiFunction(`student/subject`, "post", val, true,  null)
}



export const createAllTest = async (val) => {
    return await apiFunction(`student/create-test`, "post", val, true,  null)
}

export const getTestQuestions = async (val) => {
    return await apiFunction(`student/find-question`, "post", val, true,  null)
}


export const getStudentLists = async (val) => {
    return await apiFunction(`student/test`, "post", val, true,  null)
}

export const saveTest = async (val) => {
    return await apiFunction(`student/submit-test`, "post", val, true,  null)
}

export const addContact = async (val) => {
    return await apiFunction(`public/contact`, "post", val, true,  null)
}

export const getDashboard = async (val) => {
    return await apiFunction(`public/dashboard`, "get", val, true,  null)
}


export const getTestimonials = async (val) => {
    return await apiFunction(`public/testimonial-save`, "get", val, true,  null)
}

export const getTestSubitList = async (val) => {
    return await apiFunction(`student/completed-question-list`, "post", val, true,  null)
}

export const getBlogList = async () => {
    return await apiFunction(`public/dashboard`, "get", {}, true,  null)
}

export const forgot_password = async (val) => {
    return await apiFunction(`public/forgot-password`, "post", val, true,  null)
}

export const new_password = async (val) => {
    return await apiFunction(`public/change-password`, "post", val, true,  null)
}