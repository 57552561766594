import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';

function Privacy() {
    return (
        <>
            <div className='app-bg'>
                <Header route='methodology' />
                <div className="terms-container">
                    <h1>Privacy Policy</h1>
                    <section>
                        <p>
                            eduprepme.org (“we,” “us,” or “our”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website (www.eduprepme.com) )and use our services. By accessing or using our Website, you agree to the terms of this Privacy Policy. If you do not agree with the terms, please do not use our Website.
                        </p>
                    </section>

                    <section>
                        <h4>1. Information We Collect</h4>
                        <ul>
                            <li>
                                <span>Personal Information:</span> We may collect personally identifiable information, such as your name, email address, and other contact details when you register for an account or use our Services.
                            </li>
                            <li>
                                <span>Usage Data:</span> We automatically collect information about your interactions with our Website, such as your IP address, browser type, operating system, referring URLs, and pages viewed.
                            </li>
                            <li>
                                <span>Cookies and Similar Technologies:</span> : We use cookies and similar tracking technologies to enhance your experience, gather usage information, and improve our Services.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>2. How We Use Your Information</h4>
                        <ul>
                            <li>
                                <span>To Provide and Improve Services:</span> We use your information to provide, maintain, and improve our Services, including personalized SAT tests and performance tracking.
                            </li>
                            <li>
                                <span>User Content:</span> We may use your email address to send you updates, newsletters, and other communications related to our Services. You can opt out of these communications at any time.
                            </li>
                            <li>
                                <span>Analytics:</span> We use aggregated data to analyze trends, monitor the effectiveness of our Services, and improve user experience.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>3. How We Share Your Information</h4>
                        <ul>
                            <li>
                                <span>Third-Party Service Providers:</span> We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer support.
                            </li>
                            <li>
                                <span>Legal Requirements:</span> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., court orders or subpoenas).
                            </li>
                            <li>
                                <span>Business Transfers:</span> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the new owner.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>4. Data Security</h4>
                        <ul>
                            <li>
                                We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>5. Your Rights and Choices</h4>
                        <ul>
                            <li>
                                <span>Access and Update:</span> You have the right to access and update your personal information. You can do this by logging into your account or contacting us directly.
                            </li>
                            <li>
                                <span>Opt-Out:</span> You can opt out of receiving promotional emails from us by following the unsubscribe instructions included in those emails or by contacting us.
                            </li>
                            <li>
                                <span>Data Deletion:</span> You may request the deletion of your account and personal information by contacting us. We will comply with your request, subject to certain legal obligations.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>6. Children’s Privacy</h4>
                        <ul>
                            <li>
                                Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>7. Changes to This Privacy Policy</h4>
                        <ul>
                            <li>
                                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Website. Your continued use of our Services after the changes become effective constitutes your acceptance of the updated Privacy Policy.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>8. Contact Us</h4>
                        <ul>
                            <li>
                                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:eduprepme@gmail.com">eduprepme@gmail.com</a>.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <p>
                            <section>
                                <p>
                                    By using our Website, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.
                                </p>
                            </section>
                        </p>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default Privacy;
