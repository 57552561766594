import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from './MainPage';
import Dashboard from './Dashboard';
import Questions from './Questions';
import Signup from './Signup';
import Teams from './Teams';
import Login from './Login';
import OnlineTest from './OnlineTest';
import ForgotPassword from './ForgotPassword';
import News from './News';
import Blogs from './Blogs';
import OurMethodology from './OurMethodology';
import Terms from './Terms';
import AboutUs from './AboutUs';
import Privacy from './Privacy';
import Faq from './Faq';
import StudentDashboard from './StudentDashboard';
import { TestProvider } from './TextContext';
import ProtectedRoute from './components/ProtectedRoute';
import Toast from "./components/actionNotice";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from '@react-oauth/google';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(localStorage.getItem('student-token'));

  useEffect(() => {
    const token = localStorage.getItem('student-token');
    setIsLoggedIn(!!token); // Simplified setting of isLoggedIn
    setIsLoading(false);
  }, [session]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a spinner
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <TestProvider>
        <Router>
          <div className="app-container">
            <div className="main-content">
              <div className="content">
              <Toast />
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/methodology" element={<OurMethodology />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  {/* <Route path="/news" element={<News />} /> */}
                  {/* <Route path="/blogs" element={<Blogs />} /> */}
                  <Route path="/faq" element={<Faq />} />
                  {!isLoggedIn && <Route path="/login" element={<Login />} />}
                  {isLoggedIn && <Route path="/login" element={<Navigate to="/" />} />} {/* Redirect logged-in users */}
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/student/dashboard"
                    element={
                      // <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <StudentDashboard />
                      // </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="/online-test"
                    element={
                      // <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <OnlineTest />
                      //  </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path="/questions"
                    element={
                      // <ProtectedRoute isLoggedIn={isLoggedIn}>
                        <Questions />
                      // </ProtectedRoute> 
                    }
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          </div>
        </Router>
      </TestProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
